export default {
	data() {
		return {
			AddUsersForm: {
				account: '',
				birthday: '',
				gender: '',
				headImgUrl: '',
				idCard: '',
				mobile: '',
				rolesUuid: [],
				userCode: '',
				userType: 1,
				password: '',
				confirmPassword: '',
				AllRole: [],
			},
			usersUedact:{},
			//编辑用户验证
			usersUedactrules: {
				mobile: [{
					required: true,
					message: '请输入手机号',
					trigger: 'blur'
				}],
				rolesUuid: [{
					required: true,
					message: '请选择角色',
					trigger: 'blur'
				}]
			}
		}
	},
	created() {
		this.getAllRole();
		this.usersUedact = this.$route.query.usersUedact;
		this.usersUedact.gender=this.usersUedact.gender+'';
		console.log(this.usersUedact)
	},
	mounted() {
		sessionStorage.setItem("detail", true);
	},
	methods: {
		//select搜索角色列表
		getAllRole() {
			this.$axios
				.get("/api/web/role/findAll")
				.then(response => {
					this.AddUsersForm.AllRole = response.data.data
					console.log(this.AddUsersForm)
					var vm = this;
					if (this.AddUsersForm.AllRole != null) {
						this.AddUsersForm.AllRole.forEach(function(item1) {
							vm.$set(item1, 'value', null);
						})
					}
				})
				.catch(e => {
					console.log(e);
					/* this.$message({
						message: "请求失败",
						type: "error"
					}); */
				});
		},
		//编辑用户
		submitForm(usersUedact) {
			this.$refs[usersUedact].validate((valid) => {
				if (valid) {
					this.usersUedact.roleUuid = this.AddUsersForm.AllRole.value;
					this.$axios({
						method: "post",
						url: "/api/web/user/update", // 接口地址
						data: this.usersUedact
					}).then(res => {
						if (res.data.code === 200) {
							this.$router.push({path: 'user-list'});
							this.$message({
								message: "修改成功",
								type: "succeed",
							});
						} else {
							this.$message({
								message: "操作失败",
								type: "error",
							});
						}
					}).catch(error => {
						console.log(error);
						//this.$message.error("请求失败");
					});
				}
			})

		}
	}
}
